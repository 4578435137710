import React from 'react';

//align: left. rigth, center
//weight: bold, normal, light

function Title({ id = null, title = '', as = 'h1', align = 'start', color = '', weight = 'bold', customClass = '', size = null, fontStyle = null }) {
	const headersTypes = {
		h1: (
			<h1 id={id} className={`text-${align} text-${color} font-weight-${weight} ${customClass}`} style={{fontSize: `${size}`, fontStyle: `${fontStyle}`}}>
				{title}
			</h1>
		),
		h2: (
			<h2 id={id} className={`text-${align} text-${color} font-weight-${weight} ${customClass}`} style={{fontSize: `${size}`, fontStyle: `${fontStyle}`}}>
				{title}
			</h2>
		),
		h3: (
			<h3 id={id} className={`text-${align} text-${color} font-weight-${weight} ${customClass}`} style={{fontSize: `${size}`, fontStyle: `${fontStyle}`}}>
				{title}
			</h3>
		),
		h4: (
			<h4 id={id} className={`text-${align} text-${color} font-weight-${weight} ${customClass}`} style={{fontSize: `${size}`, fontStyle: `${fontStyle}`}}>
				{title}
			</h4>
		),
		h5: (
			<h5 id={id} className={`text-${align} text-${color} font-weight-${weight} ${customClass}`} style={{fontSize: `${size}`, fontStyle: `${fontStyle}`}}>
				{title}
			</h5>
		),
		h6: (
			<h6 id={id} className={`text-${align} text-${color} font-weight-${weight} ${customClass}`} style={{fontSize: `${size}`, fontStyle: `${fontStyle}`}}>
				{title}
			</h6>
		)
	};

	return headersTypes[as];
}

export default Title;
