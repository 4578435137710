import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { CustomInput } from 'gatsby-theme-oneway-ui-components';

function SearchModal(props) {
	const { show, handleClose } = props;
	const [searchValue, setSearchValue] = useState('');

	const handleSearchValue = (event) => {
		const value = event.target.value;

		setSearchValue(value);
	};

	return (
		<Modal show={show}  size="lg" onHide={handleClose}>
			<Modal.Header className="text-center" closeButton>
				<Modal.Title className="font-weight-bold">What are you looking for?</Modal.Title>
			</Modal.Header>
			<Modal.Body className="color-text-green font-weight-bold">
				<Form>
					<CustomInput
						name="search"
						size="md"
						type="text"
						placeholder="Type to search something..."
						value={searchValue}
						handleChange={handleSearchValue}
					/>
				</Form>
			</Modal.Body>
		</Modal>
	);
}

export default SearchModal;
