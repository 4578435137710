import React from 'react'
import { Col, Row, Button } from 'react-bootstrap';

const OpenChatButton = ({ clickAction, title }) => {
    return (
        <Row className='mx-0'>
            <Col className='px-0 py-3 mx-auto' lg={7} md={7} sm={12} xs={12}>
                <Row className='mx-0'>
                    <Button variant="primary" onClick={clickAction ? clickAction : '#'} className='mt-5 mx-auto px-5 py-3 submit-button'>
                        {title || 'CONTACT US'}
                    </Button>
                </Row>
            </Col>
        </Row>
    )
}

export default OpenChatButton
