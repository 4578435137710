import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { DefaultButton } from 'gatsby-theme-oneway-ui-components';
import { Modal, Col } from 'react-bootstrap';

function EventModal({ show, handleClose, message, eventTitle, eventType = 'info' }) {
	const eventData = useStaticQuery(graphql`
		query ModalQuery {
			site {
				siteMetadata {
					modalsType {
						modalName
						themeColor
						modalSticker
					}
				}
			}
		}
	`);

	const visualOptions = eventData.site.siteMetadata.modalsType.find((element) =>
		element.modalName.includes(eventType)
	);

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header className="text-center" closeButton>
				<Modal.Title className="font-weight-bold justify-content-center">{eventTitle}</Modal.Title>
			</Modal.Header>
			<Modal.Body className="font-weight-bold justify-content-center">{message}</Modal.Body>
			<Modal.Footer className="justify-content-center">
				<Col lg={4}>
					<DefaultButton
						title="Accept"
						variant={visualOptions.themeColor}
						display="block"
						buttonAction={handleClose}
						segmentTrack={`closeModal${eventType}`}
					/>
				</Col>
			</Modal.Footer>
		</Modal>
	);
}

export default EventModal;
