import axios from 'axios';
import { isBrowser } from './dataValidator';

const axiosIntance = axios.create({
	baseURL: process.env.GATSBY_ONEWAY_BASE_URL,
	//baseURL: 'http://localhost:5001/oneway-survivalboxes-dev/us-central1',
	headers: {
		'Content-Type': 'application/json'
	},
	timeout: 60000,
	withCredentials: false
});

export const graphlHttpResquest = async (query, variables) => {
	try {
		const httpResquest = await axiosIntance.post('/graphql', {
			query: query,
			variables: variables
		});
		return httpResquest;
	} catch (error) {
		return error;
	}
};

export const graphAuthlHttpResquest = async (query, variables) => {
	try {
		const httpResquest = await axiosIntance.post('/graphql', {
			query: query,
			variables: variables
		});
		return httpResquest;
	} catch (error) {
		return error;
	}
};

export const httpGetResquest = async (path) => {
	try {
		const httpResquest = await axiosIntance.get(path);

		console.log(httpResquest);
		return httpResquest.data;
	} catch (error) {
		return error;
	}
};

export const httpPostResquest = async (path, variables) => {
	try {
		const httpResquest = await axiosIntance.post(path, variables);

		console.log(httpResquest);
		if (httpResquest.data === '') {
			return {
				success: true
			};
		} else {
			return httpResquest.data;
		}
	} catch (error) {
		return error;
	}
};

export const trackToSegment = (type, tag, data) => {
	const segmentAction = {
		track: window.analytics.track(tag, data),
		identify: window.analytics.identify(data.userId, data)
	};

	isBrowser() && segmentAction[type];
};
