import React from 'react';
import FreshChat from 'react-freshchat';

const FreshChatComponent = (props) => {
	return (
		<FreshChat
			token={process.env.GATSBY_FRESHCHAT_TOKEN}
			onInit={(widget) => {
				widget.open();
			}}
		/>
	);
};

export default FreshChatComponent;
