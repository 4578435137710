import firebase from 'gatsby-plugin-firebase';
import jwt_decode from 'jwt-decode';
import { setCookie, getCookie, deleteCookie } from './cookies';
import { CURRENT_USER } from '../constants/storage';
import { LOAD_SIGNED_USER } from '../store/action-types';
const auth = firebase.auth;

auth &&
	auth().onAuthStateChanged(async (user) => {
		if (user) {
			const currentUser = auth().currentUser;
			if (currentUser) {
				const userData = await manageUserData(currentUser, user);
				setCookie(CURRENT_USER, userData);
			}
		}
	});

const signInWithGoogle = async () => {
	try {
		const provider = new auth.GoogleAuthProvider();

		const user = await auth().signInWithPopup(provider);
		if (user) {
			const currentUser = auth().currentUser;
			if (currentUser) {
				const userData = await manageUserData(currentUser, user);
				setCookie(CURRENT_USER, userData);
				return userData;
			}
		}
	} catch (error) {
		return error.message;
	}
};

const signInWithFacebook = async () => {
	try {
		const provider = new auth.FacebookAuthProvider();

		const user = await auth().signInWithPopup(provider);
		if (user) {
			const currentUser = auth().currentUser;
			if (currentUser) {
				const userData = await manageUserData(currentUser, user);
				setCookie(CURRENT_USER, userData);
				return userData;
			}
		}
	} catch (error) {
		return error.message;
	}
};

const signInWithEmailAndPassword = async (email, password) => {
	try {
		const user = await auth().signInWithEmailAndPassword(email, password);

		if (user) {
			const currentUser = auth().currentUser;
			if (currentUser) {
				const userData = await manageUserData(currentUser, user);
				setCookie(CURRENT_USER, userData);
				return userData;
			}
		}
	} catch (error) {
		return error.message;
	}
};

const signUpWithEmailAndPassword = async (fullname, email, password) => {
	try {
		await auth().createUserWithEmailAndPassword(email, password);

		const user = auth().currentUser;

		await user.updateProfile({
			displayName: fullname
		});
		if (user) {
			const currentUser = auth().currentUser;
			if (currentUser) {
				const userData = await manageUserData(currentUser, user);
				setCookie(CURRENT_USER, userData);
				return userData;
			}
		}
	} catch (error) {
		return error.message;
	}
};

const isLoggedIn = (dispatch) => {
	const currentUser = getCookie(CURRENT_USER);
	if (currentUser) {
		dispatch({ type: LOAD_SIGNED_USER, payload: currentUser });
		return currentUser;
	} else {
		dispatch({ type: LOAD_SIGNED_USER, payload: null });
		return false;
	}
};

const signOut = async (navigate) => {
	deleteCookie(CURRENT_USER);
	await auth().signOut();
	navigate && navigate('/');
};

const manageUserData = async (rawUserData, user) => {
	try {
		const idToken = await user.getIdToken();

		const userDataComposed = {
			displayName: rawUserData.displayName,
			email: rawUserData.email,
			emailVerified: rawUserData.emailVerified,
			photoURL: rawUserData.photoURL,
			tenantId: rawUserData.tenantId,
			uid: rawUserData.uid,
			idToken: idToken && idToken,
			decodedToken: idToken && jwt_decode(idToken)
		};
		return userDataComposed;
	} catch (error) {
		return error;
	}
};

export {
	isLoggedIn,
	signUpWithEmailAndPassword,
	signInWithEmailAndPassword,
	signInWithGoogle,
	signInWithFacebook,
	signOut
};
