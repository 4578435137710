import { isJsonString, isBrowser } from 'gatsby-theme-oneway-httprequest';

export const setCookie = (cookieName, cookieData) => {
	if (isBrowser()) {
		const today = new Date();
		const tomorrow = new Date(today);
		tomorrow.setDate(tomorrow.getDate() + 1);
		const formattedExpirationDate = new Date(tomorrow);
		const expires = 'expires=' + formattedExpirationDate.toGMTString();

		document.cookie = cookieName + '=' + encodeURI(JSON.stringify(cookieData)) + ';' + expires + ';path=/';
	}
};

export const getCookie = (cookieName) => {
	if (isBrowser()) {
		const name = cookieName + '=';
		const decodedCookie = decodeURIComponent(document.cookie);
		const entries = decodedCookie.split(';');
		for (let i = 0; i < entries.length; i++) {
			const entry = entries[i];
			while (entry.charAt(0) === ' ') {
				entry = entry.substring(1);
			}
			if (entry.indexOf(name) === 0) {
				try {
					const jsonData = decodeURI(entry.substring(name.length, entry.length));
					const jsonVerified = isJsonString(jsonData);
					if (jsonVerified) return JSON.parse(jsonData);
					return false;
				} catch (e) {
					return false;
				}
			}
		}
	}
};

export const deleteCookie = (cookieName) => {
	if (isBrowser()) {
		const deletedCookieDate = 'expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		document.cookie = `${cookieName}=;${deletedCookieDate}`;
	}
};
